import React from "react";
import CallsNotSigned from "./pendencies/CallsNotSigned";
import Pendencies from "./pendencies/Pendencies";
import PlanTherapeutic from "./pendencies/PlanTherapeutic";
import {canSign, notSignForCompany} from "../../utils";

export default function ListPendencies({ openModalTertiary, openAny, auth, professional}) {

    return (
        <div className="row" >
            <div className="col-md-6">
                <div className="row">
                    <div className="col-md-12 ">
                        <Pendencies openModalTertiary={openModalTertiary} professional={professional} openAny={openAny} auth={auth} />
                    </div>
                    {canSign() && !notSignForCompany(auth.company.id) ? <div className="col-md-12 ">
                        <CallsNotSigned openModalTertiary={openModalTertiary} professional={professional} openAny={openAny} auth={auth} />
                    </div> : <></>}
                </div>
            </div>
            <div className="col-md-6">
                <div className="row">
                    <div className="col-md-12 ">
                        <PlanTherapeutic openModalTertiary={openModalTertiary} professional={professional} openAny={openAny} auth={auth}  />
                    </div>
                </div>
            </div>
        </div>
    );
}
