import React, {lazy, Suspense, useCallback, useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { deletePhotoPatient, destroyPatient, getPatient, updatePatient, uploadPhotoPatient} from "../../store/actions/patientsActions";
import { openModal } from "../../store/actions/uiActions";
import { Contents, Header, MenuMobile } from "../../components/Tab";
import PatientTop from "./patientTop";
import { checkPermission } from "../../utils";
import Img5 from "../../assets/img/5.png";
const Info = lazy(() => import('./Info'));
const DiagnosticsController = lazy(() => import('./DiagnosticsController'));
const AnamneseController = lazy(() => import('./AnamneseController'));
const EvolutionController = lazy(() => import('./EvolutionController'));
const DocumentController = lazy(() => import('../document/DocumentController'));
const ReceiveController = lazy(() => import('../finance/ReceiveController'));
const ParentalController = lazy(() => import('./ParentalController'));
const ListView = lazy(() => import('../calendar/ListView'));
const TherapeuticPlanController = lazy(() => import('./TherapeuticPlanController'));




function Patient({setHeaderRight,item, openModal, updatePatient, loadingPatient, loading, getPatient, plan, uploadPhotoPatient, deletePhotoPatient, user, orientations, destroyPatient, evolutions, anamneses, patientPrivate, isMobile, layoutRef, setHeaderNavigation}) {

  const [tab, setTab] = useState(1);

  const { id } = useParams();
  const [loadingTab, setLoadingTab] = useState(false);
  const [novo, setNovo] = useState(false);
  const dispatch = useDispatch();

  let history = useHistory();

  useEffect(() => {
    function click(ev) {

      if (ev.srcElement.closest("#menupatient") == null) {
        setNovo(false);
      }
    }

    if (novo) {
      window.addEventListener("click", click, false);
      return () => {
        window.removeEventListener("click", click, false);
      };
    }
  }, [novo]);

  useEffect(() => {

    const loadData = async () => {
      getPatient(id);
      await new Promise((r) => setTimeout(r, 1000));
      setLoadingTab(true);
    };

    loadData();

    return () => {
      dispatch({type:'SET_PATIENT','patient':''});
    };
  }, [dispatch,getPatient,id]);

  useEffect(() => {
    if(isMobile) {
      layoutRef.current.classList.remove('container-xxl');

      setHeaderRight(
          <>
            <button className="btn btn-icon btn-active-icon-primary" style={{justifyItems: "flex-end", justifyContent: "flex-end"}} onClick={() => setNovo(!novo)}>
              <span className="svg-icon svg-icon-2x">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                  <path d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="#000" />
                </svg>
              </span>
            </button>
            <div className={"dropdown-menu " + (novo ? "show" : "")} style={{marginTop: 10, right: 0}} id="menupatient">
              <MenuMobile tab={tab} menu={menu()} setTab={setTab} auth={user}/>
            </div>
          </>
      )
    }

  }, [isMobile, layoutRef, novo]);

  const components = {
    1: <Info item={item} user={user} loadingPatient={loadingPatient}/>,
    2: <AnamneseController patient={item} />,
    3: <EvolutionController patient={item} />,
    4: <TherapeuticPlanController patient={item} />,
    5: <ParentalController patient={item?.id} />,
    6: <DiagnosticsController patient={item?.id} />,
    7: <DocumentController id={item?.id} typeModel={1} title="Anexos" key={1} />,
    8: <ListView id={item?.id} professionais={patientPrivate} name={item?.name} />,
    9: <ReceiveController patient={item} name={item?.name} title="Financeiro" isMobile={isMobile} layoutRef={layoutRef} setHeaderNavigation={setHeaderNavigation} typeModel={1} />
  }

  const ActiveTabComponent = components[tab];
  const menu = useCallback(() => {

    const shared = [
      {
        "id": "info",
        "description": "Informações",
        "contents": 1,
        "notViewCompany": [],
        "order": 1,
        "comingSoon": false,
        "comingSoonNotCompany": [],
      },
      {
        "id": "anamnese",
        "description": "Anamnese",
        "contents": 2 ,
        "count": item.anamnese,
        "notViewCompany": [3],
        "order": 2,
        "permission": "anamnese.list",
        "comingSoon": false,
        "comingSoonNotCompany": [],
      },
      {
        "id": "evolution",
        "description": "Evoluções",
        "contents": 3 ,
        "count": item.calls,
        "notViewCompany": [],
        "order": 3,
        "permission": "evolution.list",
        "comingSoon": false,
        "comingSoonNotCompany": [],
      },
      {
        "id": "plan",
        "description": "Plano Terapêutico",
        "contents": 4 ,
        "alert": item.therapeutic === 0,
        "notViewCompany": [],
        "order": 4,
        "permission": "plan.list",
        "comingSoon": false,
        "comingSoonNotCompany": [],
      },
      {
        "id": "orient",
        "description": "Orientações",
        "contents": 5 ,
        "alert": item.orientation === 0,
        "notViewCompany": [],
        "order": 5,
        "permission": "orient.list",
        "comingSoon": false,
        "comingSoonNotCompany": [],
      },
      {
        "id": "diagnostic",
        "description": "Diagnósticos",
        "contents": 6 ,

        "notViewCompany": [],
        "order": 6,
        "permission": "diagnostic.list",
        "comingSoon": false,
        "comingSoonNotCompany": [],
      },
      {
        "id": "document",
        "description": "Anexos",
        "contents": 7 ,
        "notViewCompany": [],
        "order": 7,
        "permission": "documents.list",
        "comingSoon": false,
        "comingSoonNotCompany": [],
      },
      {
        "id": "calls",
        "description": "Próximos Atendimentos",
        "notViewCompany": [],
        "contents": 8 ,
        "order": 8,
        "permission": "calls.list",
        "comingSoon": false,
        "comingSoonNotCompany": [],
      },
      {
        "id": "finance",
        "description": "Financeiro",
        "notViewCompany": [],
        "contents": 9 ,
        "order": 9,
        "comingSoon": true,
        "permission": "receive.patient.list",
        'comingSoonNotCompany':[1,2,3,23,24,29,33,34,37,43,48,64,72,83,84,86,94,97,98,53,56,101,102,107,114,115,116,117,104,120,119,122,89,122,85,124,125,127,128,135,136,137,134,139,140,141,142,144,145,146,148,150,151,152,153,118,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175,176,177,178,179,180,181,182,183,184,185,186,187,188,189,190,191,192,193,194,195,196,197,198,199,200,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219,220,221,222,223,224,225,226,227,228,229,230,231,232,233,235,236,237,238,239,240,241,242,243,244,245,246,247,248,249,250,251,252,253,254,255,256,257,258,259,260, 261, 262, 263, 264, 265, 266, 267, 268, 269,  271, 273, 274, 275, 276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 322, 323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340, 341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 360, 361, 362, 363, 364, 365, 366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 380, 381, 382, 383, 384, 385, 386, 387, 388, 389, 390, 391, 392, 393, 394, 395, 396, 397, 398, 399, 400, 130,161]
      },

    ];
    const filterNotViewCompany = shared.filter(comp => {
      return (!comp.permission || checkPermission(user.permissions, comp.permission)) && !comp.notViewCompany.includes(user.company.id);
    });

    const menu = [...filterNotViewCompany];
    menu.sort(function(a, b) {

      if (a.order > b.order) {
        return 1;
      }

      if (a.order < b.order) {
        return -1;
      }

      return 0;
    });

    return menu;
  }, [item, user, plan, orientations, updatePatient, loadingPatient, evolutions, anamneses, patientPrivate]);

  if (item.length === 0) {
    return (
      <div style={{ textAlign: "center" }}>
        <br /><br />
        <br /><br />
        <h1>O paciente não foi encontrado!</h1>
        <br /><br />
        <br /><br />
        <img src={Img5} style={{ width: 400 }} alt="img" />
      </div>
    );
  }

  return (
    <>
      { !isMobile && <div className="page-title d-flex flex-rpw align-items-start  flex-wrap me-lg-2 pb-lg-0" style={{ marginBottom: 10 }}>
        <h3 style={{ flex: 1 }}>
          <button onClick={() => history.goBack()} className="btn btn-icon btn-secondary" style={{ marginRight: 20 }}>
            <span className="svg-icon svg-icon-muted svg-icon-2hx">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z" fill="black" />
                <path opacity="0.3" d="M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z" fill="black" />
              </svg>
            </span>
          </button>
          Ficha do paciente
        </h3>
      </div>}
      <PatientTop
          isMobile={isMobile}
          openModal={openModal}
          item={item}
          loading={loading}
          edit={true}
          auth={user}
          permission="patients"
          destroyPatient={destroyPatient}
          type="patient"
          onUpload={(file) => uploadPhotoPatient(file, item.id)}
          onDelete={() => {
            deletePhotoPatient(item.id);
          }}
          menu={<Header tab={tab} menu={menu()} setTab={setTab} auth={user} />}
      />
      <Suspense fallback={<div>Loading...</div>}>
        {ActiveTabComponent}
      </Suspense>
    </>
  );
}


const mapStateToProps = state => ({
  item: state.patients.item,
  loading: state.patients.loading,
  user: state.auth,
  loadingPatient: state.patients.loadingRequest,
  evolutions: state.patients.evolution,
  anamneses: state.patients.anamnese,
  plan: state.patients?.therapeutic,
  orientations: state.orientations,
  patientPrivate: state.patients.patientPrivate,
});

const mapDispatchToProps = dispatch => ({
  openModal: (modal, payload) => dispatch(openModal(modal, payload)),
  updatePatient: (data, id, type) => dispatch(updatePatient(data, id, type)),
  getPatient: (id) => dispatch(getPatient(id)),
  uploadPhotoPatient: (data, id) => dispatch(uploadPhotoPatient(data, id)),
  deletePhotoPatient: (id) => dispatch(deletePhotoPatient(id)),
  destroyPatient: (id, patient, typeModel) => dispatch(destroyPatient(id, patient, typeModel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
