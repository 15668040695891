import {DateTime} from "luxon";
import React from "react";
import {formatDateNotHour, formatHourStartAndEnd, getName} from "../../../utils";
import {Avatar} from "../../../components/Avatar";
import styled from "styled-components";

const TooltipText = styled.div`
    flex-direction: row;
    display: flex;
    cursor: pointer;
    width: 100%;
`;

const TooltipBox = styled.div`
  position: absolute;
  top: -220px;
  z-index: 99999;
  visibility: hidden;
  color: transparent;  
  background-color: transparent;
  width: 200px;
  height: 200px;
  border-radius: 4px;


  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 150px;
    top: 189px;
    position: absolute;
    border: 10px solid transparent;
    transform: rotate(315deg);
  }
`;

const TooltipCard = styled.div`
    & ${TooltipText}:hover + ${TooltipBox} {
        visibility: visible;
        color: #000;
        background-color: #fff;
        width: 320px;
        padding: 8px 8px;
        left: -80px;
        z-index: 999999;
        &:before {
            border-color: transparent transparent #fff #fff;
        }
    }
`;

export default function Event({event, eventClick,isMobile}){

    let color = '#fff';
    let acronym = ''

    switch (parseInt(event.type_schedule.id)){
        case 2:
            color = '#c6eaff';
            acronym = "E";
            break;
        case 1:
            color = '#a9fca9';
            acronym = "AV";
            break;
        case 3:
            color = '#ededed';
            acronym = "A";
            break;
        case 4:
            color = '#e2c7bc';
            acronym = "D";
            break;
        case 5:
            color = '#42d5ee';
            acronym = "P";
            break;
        case 6:
            color = '#ff9800';
            acronym = "O";
            break;
        case 7:
            color = '#9e9e9e';
            acronym = "DC";
            break;
        default:
            break;
    }

    if (event.replacement === 1) {
        color = '#ffeca7';
        acronym = "R";
    }

    if(isMobile){
        return (
            <button
                onClick={() => eventClick(event)}
                className={`list-group-item list-group-item-action`}
                style={{
                    padding: 10,
                    paddingLeft: 5,
                    height: 60,
                    flexDirection: 'row',
                    display: 'flex',
                    borderLeftColor: event.status.color,
                    borderLeftWidth: 8,
                    background: color,
                    paddingRight: 8
                }}
            >
                <div style={{fontSize: 10}}>{DateTime.fromSQL(event.start_date).toFormat('HH:mm')}</div>
                {
                    event && <div
                        style={{
                            marginLeft: 10,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div style={{marginRight:10}}>
                            <Avatar row={event.patient} w={"w-40px"} h={"h-40px"} />
                        </div>
                        <div>
                            <h5>{getName(event.patient.name)} [{acronym}] </h5>
                            <div style={{display: 'flex', flexDirection: 'row', marginTop: -2}}>
                                <Avatar row={event.professional} w={"w-20px"} h={"h-20px"}/>
                                <div style={{marginLeft: 5, marginTop: 2}}>
                                    {getName(event.professional.name)} - {event.treatment.specialization.profession.abbreviation ?? event.treatment.specialization.profession.description} {event.treatment.specialization && event.treatment.specialization.description}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </button>
        )
    }

    return (
        <>
            <TooltipCard
                onClick={() => eventClick(event)}
                className={`list-group-item list-group-item-action`}
                style={{
                    padding: 3,
                    paddingLeft: 5,
                    height: 38,
                    display: 'flex',
                    borderLeftColor: event.status.color,
                    borderLeftWidth: 5,
                    background: color,
                    paddingRight: 8
                }}
            >
                <TooltipText>
                    <div style={{fontSize: 10}}>{DateTime.fromSQL(event.start_date).toFormat('HH:mm')}</div>
                    {
                        event && <div
                            style={{marginLeft: 10, width: '100%', display: 'flex', flexDirection: 'column'}}
                        >
                            <div style={{fontSize: 11, width: 210}}>[{acronym}] {getName(event.title)}</div>
                            <div style={{
                                color: '#737171',
                                fontSize: 10,
                                width: 210
                            }}> {event.treatment.specialization.profession.abbreviation ?? event.treatment.specialization.profession.description} {event.treatment.specialization && event.treatment.specialization.description} - {getName(event.professional.name)}</div>
                        </div>
                    }
                </TooltipText>
                <TooltipBox className={"shadow"}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        <div style={{marginRight: 10}}>
                            <Avatar row={event.patient} w={"w-50px"} h={"h-50px"}/>
                        </div>
                        <div>
                            <h5 style={{marginTop: 5}}>{getName(event.patient.name)}</h5>
                            <div style={{display: 'flex', flexDirection: 'row', marginTop: 5}}>
                                <Avatar row={event.professional} w={"w-20px"} h={"h-20px"}/>
                                <div style={{marginLeft: 5, marginTop: 2}}>
                                    {getName(event.professional.name)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <p>Data: {formatDateNotHour(DateTime.fromSQL(event.start_date))} às {formatHourStartAndEnd(DateTime.fromSQL(event.start_date), DateTime.fromSQL(event.start_date))}</p>
                    <p style={{marginTop: -10}}>Tratamento: {event.treatment.specialization.profession.description} {event.treatment.specialization && event.treatment.specialization.description}</p>
                    <p style={{marginTop: -10}}>Tipo de agendamento: {event.type_schedule.description}</p>
                    <div style={{gap:5,display:"flex",flexWrap: "wrap"}}>
                        <div
                            className="badge badge-light-primary"
                            style={{
                                background: event.status.color,
                                color: "#fff",
                            }}
                        >
                            {event.status.description}
                        </div>
                        {
                            event.replacement ?
                                <div
                                    className="badge badge-light-warning"
                                >
                                    Reposição
                                </div>
                            : <></>
                        }
                        {
                            event?.signed_document ?
                                <div
                                    className="badge badge-info"
                                    style={{
                                        background: event.status.color,
                                        color: "#fff",
                                    }}
                                >
                                    Atendimento assinado
                                </div>
                                :
                                <></>
                        }
                        {event.type_address === 4 ? <div className="badge badge-info" >Teleatendimento</div> : <></>}
                        {event.rescheduled ? <div className="badge badge-primary" style={{backgroundColor: '#ffc107'}}>Remarcado</div> : <></>}
                    </div>
                </TooltipBox>
            </TooltipCard>
        </>
    );
}